.FooterPrediContent {
    padding-top: 2vh;
    width: 70vw;
    margin: auto;
}

.FooterPrediContent a{
    color: #e0e0e0;
    text-decoration: none;
    font-weight: 500;
}

.FooterPrediContent a:hover{
    color: #ffffff;
    transition: 0.2s;
    cursor: pointer;
}

.FooterContent {
    padding-top: 33vh;
    position: absolute;
    z-index: 3;
    color: white;
    width: 70vw;
    margin: auto;
}

.FooterPrediImage {
    height: 60vh;
    width: 100%;
    background: linear-gradient(#00000000, #000000ee);
    position: absolute;
    z-index: 2;
}

.FooterImage {
    background-image: url('https://sofia-open.com/wp-content/uploads/2024/04/MG_1833-Pano.jpg');
    background-position-y: -8vh;
    background-position-x: 0;
    border-radius: 15vw 15vw 0 0;
    background-size: 100%;
    height: 60vh;
    width: 100%;
    align-items: center;
    position: absolute;
    z-index: 0;
}

.FooterFirstRow{
    position: absolute;
    z-index: 5;
    padding-top: 36vh;
    display: flex;
    flex-wrap: wrap;
    width: 70vw;
    margin: auto;
    align-items: center;
    justify-content: space-between;
}

.FirstRowFirstColumn{
    height: 15vh;
}

.FooterLogo{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.FirstRowSecondColumn{
    height: 16vh;
    color: white;
    display: grid;
    align-items: center
}

.FirstRowSecondColumn p{
    margin: 0;
}

.FirstRowSecondColumn a{
}

.FirstRowThirdColumn{
}

.FooterFacebookLogo{
    height: 4.2vh;
    padding-right: 1vw;
    opacity: 0.8;
}

.FooterFacebookLogo:hover{
    opacity: 1;
}

.FooterInstagramLogo{
    height: 4vh;
    opacity: 0.8;
}

.FooterInstagramLogo:hover{
    opacity: 1;
}

.FooterYouTubeLogo{
    padding-left: 1vw;
    height: 5vh;
    transform: translateY(0.4vh);
    opacity: 0.8;
}

.FooterYouTubeLogo:hover{
    opacity: 1;
}

.FooterSecondRow{
    position: absolute;
    z-index: 4;
    padding-top: 54vh;
    display: flex;
    width: 70vw;
    margin: auto;
    justify-content: space-between;
}


@media (max-width: 800px) {
    .FooterPrediImage{
        height: 45vh;
        background: linear-gradient(#00000022, #000000ef);
        border-radius: 15vw 15vw 0 0
    }
    .FooterImage{
        height: 45vh;
        background-size: cover;
        background-position-y: 0;
        background-position-x: -65vw;
    }
    .FooterFirstRow{
        padding-top: 2vh;
        justify-content: center;
    }
    .FirstRowFirstColumn{
        height: 8vh;
    }
    .FirstRowSecondColumn{
        margin-top: 1vh;
        height: 16vh;
        font-size: 1.5vh;
        text-align: center;
    }
    .FooterSecondRow{
        padding-top: 32vh;
        flex-wrap: wrap;
        font-size: 1.5vh;
    }
}