.ArticlesHeader{
    width: 100%;
    height: 30vh;
    background-color: #000000;
}

.ArticlesHeader h1{
    z-index: 1;
    top: 14%;
}

.ArticlesHeaderImage{
    width: 100%;
    height: 30vh;
    object-fit: cover;
    opacity: 0.6;
}

.ArticlesMain{
    width: 80vw;
    display: flex;
    margin: 2vw auto;
}

.ArticlesList{
    width: 100%;
    margin-right: 2vw;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(30vw,1fr));
    grid-gap: 2vw;
}

.ArticlesList a{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #151515;

    clip-path: inset(0);
    --f: 1.05;
}

.ArticlesList h3 {
    background: white;
    z-index: 1;
}

.ArticlesList a:hover img.ArticlesListImage{
    transform: scale(var(--f));
    cursor: pointer;
}

.ArticlesListImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s;
    position: relative;
}

.ArticlesSidebar{
    width: 20vw;
    text-transform: uppercase;
    color: #151515;
}

.ArticlesSidebarTitle{
    font-size: 2.5vh;
    font-weight: 600;
    margin-top: .5vh;
}

.ArticlesSidebarFilterOption{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: .8vh;
    transform: translateX(0.2vh);
}

.ArticlesSidebarFilterOption input{
    height: 1.6vw;
    width: 1.6vw;
    margin: 0.5vh 1vh .5vh 0;
}

.ArticlesSidebarFilterOption p{
    margin: 0;
    font-size: 2.5vh;
    transform: translateY(-0.25vh);
}




.ArticleBackground{
    position: absolute;
    height: 60vh;
    width: 100%;
}
.ArticleBackground img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.ArticleHeader{
    position: relative;
    margin-top: 3vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
}

.ArticleHeaderImage{
    height: 77vh;
    width: 100%;
    object-fit: cover;
}

.ArticleHeaderLogo{
    height: 7vh;
    transform: translateY(-3.5vh);
}

.ArticleHeaderInfo{
    transform: translateY(-2.5vh);
    color: #151515;
    text-transform: uppercase;
    margin: 0;
}

.ArticleHeaderTitle{
    font-weight: 600;
    font-size: 3.8vh;
    text-align: center;
    margin: 0;
    transform: translateY(-1.7vh);
    width: 70vw;
}

.ArticleMain{
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1vh;
}

.ArticleMain p{
    margin-top: 0;
}
@media (max-width: 800px) {
    .ArticlesMain{
        width: 95vw;
    }

    .ArticlesSidebarTitle {
        font-size: 2.3vh;
        text-align: center;
    }

    .ArticlesSidebarFilterOption{
        margin-top: 0.5vh;
    }

    .ArticlesSidebarFilterOption input{
        height: 3vw;
        width: 3vw;
        margin: 0 0.5vh 0 0;
    }

    .ArticlesSidebarFilterOption p{
        font-size: 1.8vh;
    }



    .ArticleBackground {
        height: 20vh;
    }

    .ArticleHeader{
        width: 85vw;
    }
    .ArticleHeaderImage {
        height: 30vh;
    }

    .ArticleHeaderTitle{
        width: 85vw;
    }
    .ArticleHeaderLogo {
        height: 5vh;
        transform: translateY(-2.5vh);
    }

    .ArticleHeaderInfo {
        font-size: 1.7vh;
    }

    .ArticleMain{
        word-wrap: break-word;
        width: 85vw;
    }
}
