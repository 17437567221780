.SkopjeOpen .Main{
    padding-top: 10vh;
}

.SkopjeOpen .Information{
    width: 70vw;
    margin: auto;
}

.SkopjeOpen .Information p{
    text-align: center;
    margin: 0;
    padding-right: 1vw;
    padding-left: 1vw;
    color: white;
}

.SkopjeOpen .Information a{
    color: white;
}

.SkopjeOpen .Information h3 {
    font-size: 5vh;
    margin-top: 0;
    font-weight: 600;
    color: white;
}

.SkopjeOpen .Information h4{
    margin: 0;
    font-size: 2.7vh;
    font-weight: 600;
    color: white;
}

.SkopjeOpen .line{
    border-bottom: solid 3px #252525;
    display: inline-block;
    width: 8vw;
    margin-bottom: 0.7vh;
}

.SkopjeOpen .InformationGridLeft{
    display: grid;
    grid-template-columns: repeat(3, 1fr) repeat(2, 0.5fr);
    grid-template-rows: .9fr repeat(3, 1fr) .9fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    margin-top: 1vh;
    margin-bottom: 4vh;
}

.SkopjeOpen .InformationGridLeftBack{
    grid-area: 1 / 1 / 6 / 4;
}

.SkopjeOpen .InformationGridLeftFront {
    grid-area: 2 / 3 / 5 / 6;
    background: rgb(4,86,255);
    /*background: radial-gradient(circle, rgba(4,86,255,1) 31%, rgba(48,116,255,.9) 100%);*/
    background: linear-gradient(to bottom, #0456FF 0%, #0044d0ef 100%);
    border-radius: .5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.36), inset 4px 4px 9px 0 rgba(0, 0, 0, 0.1);
}

.SkopjeOpen .InformationGridLeftBack img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SkopjeOpen .InformationGridRight{
    display: grid;
    grid-template-columns: repeat(2, .5fr) repeat(3, 1fr);
    grid-template-rows: .9fr repeat(3, 1fr) .9fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    margin-bottom: 4vh;
}

.SkopjeOpen .InformationGridRightBack{
    grid-area: 1 / 3 / 6 / 6;
}

.SkopjeOpen .InformationGridRightFront{
    grid-area: 2 / 1 / 5 / 4;
    background: rgb(4,86,255);
    background: linear-gradient(to bottom, #0456FF 0%, #0044d0ef 100%);
    border-radius: .5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.36), inset 4px 4px 9px 0 rgba(0, 0, 0, 0.1);
}

.SkopjeOpen .InformationGridRightBack img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SkopjeOpen .Documents{
    width: 70vw;
    margin: auto;
}

.SkopjeOpen .DocumentsList{
    margin-top: 1vh;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px,1fr));
    grid-gap: 4vw;
}

.SkopjeOpen #nocolor{
    filter: saturate(10%);
}
.SkopjeOpen #nocolor:hover{
    transform: translateY(0);
    cursor: auto;
}

.SkopjeOpen .DocumentsList a{
    position: relative;
    transition: 0.25s;
}

.SkopjeOpen .DocumentsList img{
    width: 100%;
    object-fit: cover;
}

.SkopjeOpen .DocumentsList a:hover{
    transform: translateY(-2.2vh);
}

.SkopjeOpen .DocumentsListName{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 8vh;
    color: black;
    text-align: center;
    bottom: 2.5vh;
    text-transform: uppercase;
    font-size: 2.4vh;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}

.SkopjeOpen .Schedule{
    width: 70vw;
    margin: auto;
}

.SkopjeOpen .Live{
    width: 88vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SkopjeOpen .Live h2{
    width: 70vw;
    margin: auto;
}

.SkopjeOpen .Live span{
    margin-left: 14.6vw;
    margin-top: 6.3vh;
    position: absolute!important;
    left: 0!important;
}


.SkopjeOpen .LiveRow{
    display: flex;
    margin-top: 2.5vh;
}

.SkopjeOpen .LiveElement{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SkopjeOpen .LiveElement iframe{
    position: absolute;
    width: 77%;
    height: 43%;
}

.SkopjeOpen .LiveElement img{
    width: 100%;
    height: 100%;
}

.SkopjeOpen .Schedule img{
    width: 100%;
}

.SkopjeOpen .News{
    position: relative;
    width: 100%;
    height: 88vh;
    transform: translateX(-50%);
    left: 50%;
    background: linear-gradient(to bottom, #0456FF 0%, #0044d0ef 100%);
    color: white;
    margin-top: 2vh;
}

.SkopjeOpen .News h2{
    width: 70vw;
    margin: 0 auto 0;
    padding-top: 1.5vh;
}

.SkopjeOpen .News .line{
    border-bottom: solid 3px #ffff;
    margin-left: 14.5vw;
}

.SkopjeOpen .NewsRow{
    width: 70vw;
    margin: 50px auto 0;
    display: flex;
    justify-content: space-between;
}

.SkopjeOpen .NewsRow a{transition: 0.3s;}
.SkopjeOpen .NewsRow a:hover{
    transform: scale(1.1);
}

.SkopjeOpen .NewsRow>a img{
    width: 100%;
    object-fit: contain;
}

.SkopjeOpen #news1{
    position: relative;
    top: 10vh;
    width: 20vw;
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.SkopjeOpen #news2{
    position: relative;
    width: 20vw;
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.SkopjeOpen #news3{
    position: relative;
    top: -10vh;
    width: 20vw;
    color: white;
    cursor: pointer;
    text-decoration: none;
}

.SkopjeOpen .NewsAll{
    width: 15vw;
    height: 6vh;
    margin-left: auto;
    margin-right: 16.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    outline-style: solid;
    outline-color: white;
    border-radius: 3vh;
    transition: .2s;
    color: white;
    text-decoration: none;
}
.SkopjeOpen .NewsAll h3{
    transform: translateY(-0.2vh);
}

.SkopjeOpen .NewsAll:hover{
    background: white;
    color: #0F51DA;
    cursor: pointer;
}

.SkopjeOpen .Results{
    width: 70vw;
    margin: auto;
}

.SkopjeOpen .ResultsRow{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 7vh 6vh 10vh;
    grid-column-gap: 0;
    grid-row-gap: 0;
    margin: 0 0 2vw 0;
}

.SkopjeOpen .ResultCategory{
    text-align: center;
    margin: 0 0 2.5vw 0;
    font-size: 1.7vw;
    font-weight: 700;
}

.SkopjeOpen .Result1 {
    grid-area: 1 / 2 / 4 / 3;
    position: relative;
    background: radial-gradient(circle, rgba(254,225,1,1) 0%, rgba(214,175,54,1) 90%);
    z-index: 1;
}
.SkopjeOpen .Result2 {
    grid-area: 2 / 1 / 4 / 2;
    position: relative;
    background: radial-gradient(circle, rgba(215,215,215,1) 0%, rgba(167,167,173,1) 90%);
    z-index: 1;
}
.SkopjeOpen .Result3 {
    grid-area: 3 / 3 / 4 / 4;
    position: relative;
    background: radial-gradient(circle, rgba(167,112,68,1) 0%, rgba(130,74,2,1) 90%);
    z-index: 1;
}
.SkopjeOpen .Result4 {
    grid-area: 3 / 4 / 4 / 5;
    position: relative;
    background: radial-gradient(circle, rgba(167,112,68,1) 0%, rgba(130,74,2,1) 90%);
    z-index: 1;
}

.SkopjeOpen .ResultNumber{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    margin: 0;
    font-size: 80px;
    color: white;
    z-index: 2;
    opacity: 50%;
}

.SkopjeOpen .ResultsRow p{
    margin: 0;
    transform: translateY(-1.7vw);
    text-align: center;
    white-space: nowrap;
    word-break: keep-all;
    font-size: 1.6vw;
    font-weight: 600;
    z-index: 3;
    position: relative;
}

.SkopjeOpen .ResultCategoryImages{
    margin: 2vw 0 0 0;
    text-align: center;
}

.SkopjeOpen .ResultsRowImages{
    display: flex;
    flex-direction: row;
}

.SkopjeOpen .ResultsRowImages img {
    width: 23vw;
    margin-right: 0.5vw;
}



@media (max-width: 800px) {
    .SkopjeOpen h1{
        font-size: 7.5vw;
        top: 68%;
    }
    .SkopjeOpen .HeaderMenu{
        height: 8vh;
        bottom: -4.5vh;
    }
    .SkopjeOpen .Main{
        width: 100vw;
        padding-top: 3vh;
    }
    .SkopjeOpen .Information{
        width: 90vw;
    }
    .SkopjeOpen .InformationGridLeft{
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        height: 40vh;
    }
    .SkopjeOpen .InformationGridLeftBack {
        grid-area: 1 / 1 / 6 / 5;
    }
    .SkopjeOpen .InformationGridRight{
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(5, 1fr);
        height: 40vh;
    }
    .SkopjeOpen .InformationGridRightBack{
        grid-area: 1 / 2 / 6 / 6;
    }
    .SkopjeOpen .Information h3 {
        font-size: 2.7vh;
        text-align: center;
    }
    .SkopjeOpen .Information h4{
        font-size: 1.6vh;
        text-align: center;
    }
    .SkopjeOpen .Information a{
        font-size: 1.3vh;
        text-align: center;
    }
    .SkopjeOpen .Information p{
        font-size: 1.2vh;
    }
    .SkopjeOpen .Documents{
        width: 90vw;
    }
    .SkopjeOpen .Documents h3{
        font-size: 1.7vh;
    }
    .SkopjeOpen .DocumentsListName{
        bottom: 1.3vh;
        height: 5vh;
    }
    .SkopjeOpen .Schedule{
        width: 90vw;
    }
    .SkopjeOpen .News{
        height: 50vh;
    }
    .SkopjeOpen .NewsRow{
        width: 95vw;
    }
    .SkopjeOpen .NewsAll{
        margin-right: 14vw;
        width: 35vw;
        height: 4vh;
    }
    .SkopjeOpen #news1,#news2,#news3{
        width: 30vw;
    }
    .SkopjeOpen .Live{
        width: 95vw;
        margin-left: auto;
        margin-right: auto;
    }
    .SkopjeOpen .LiveRow {
        flex-direction: column;
    }
    .SkopjeOpen .Results{
        width: 95vw;
    }
    .SkopjeOpen .Results p{
        font-size: 3vw;
        transform: translateY(-3.3vw);
    }
    .SkopjeOpen .ResultsRowImages img {
        width: 32vw;
        margin-right: 0;
    }
    .SkopjeOpen .ResultsRow p{
        white-space: unset;
        word-break: unset;
        font-size: 2.3vw;
    }
    .SkopjeOpen .ResultCategory{
        font-size: 5vw;
    }
}