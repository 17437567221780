html {
    scroll-behavior: smooth;
}

.App {
}

.SkopjeOpen h1, .SofiaOpen h1, .App h1, .Article h1, .Articles h1{
    position: absolute;
    margin: 0;
    font-size: 12.7vh;
    font-weight: 400;
    transform: translateX(-50%);
    left: 50%;
    top: 60%;
    color: white;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    white-space: nowrap;
}

.SkopjeOpen h2, .SofiaOpen h2, .App h2, .Article h2, .Articles h2{
    margin: 2vh 0 0;
    font-size: 4vh;
    font-weight: 600;
    transform: translateX(-1.5px);
}

.SkopjeOpen h3, .SofiaOpen h3, .App h3, .Article h3, .Articles h3{
    margin: 0;
    font-size: 3vh;
    font-weight: 600;
}

.Header{
    position: relative;
    height: 75vh;
}

.HeaderImage{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 0 65%;
}

.HeaderMenu{
    position: absolute;
    display: flex;
    bottom: -10vh;
    transform: translateX(-50%);
    left: 50%;
    background-color: white;
    border-radius: 1vh;
    box-shadow: 0 3px 41px 0 rgba(0, 0, 0, 0.17);
    border: solid 1px #e1e1e1;
}

.HeaderMenuItem{
    border-radius: 1vh;
    height: 21vh;
}

.SkopjeOpen svg, .SofiaOpen svg, .App svg, .Article svg, .Articles svg{
    fill: #252525;
    height: 15vh;
}

#header1{
    margin-left: 1vh;
}
#header2{
    margin-left: 1vh;
    margin-right: 1vh;
}

.HeaderMenuItem a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1vh 1vw;
    text-align: center;
    text-decoration: none;
    color: #050505;
    transition: .3s;
}

.HeaderMenuItem img{
    height: 15vh;
    object-fit: contain;
}

.HeaderMenuItem a:hover{
    background-color: #0456ff;
    color: white;
    transform: scale(1.27);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.36);
    border-radius: 1vh;
    z-index: 2;
}

.HeaderMenuItem a:hover>svg>path{
    fill: white!important;
}
.HeaderMenuItem a:hover>svg>g>path{
    fill: white!important;
}
.HeaderMenuItem a:hover>svg>g>g>g>g>path{
    fill: white!important;
}



.App .liveEvent{
    position: absolute;
    width: 9vw;
    top: -4vh;
    margin-right: 22vh;
    /*left: -2.5vw;*/
    transform: rotate(-35deg);
}

.App .closedEvent{
    position: absolute;
    width: 9vw;
    top: -4vh;
    margin-right: 22vh;
    transform: rotate(-35deg);
}

.App .Main{
}

.App .MainRow{
    width: 70vw;
    margin: auto;
    display: flex;
}

.App .MainRowText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1vw 0 1vw;
    text-align: justify;
    color: #686868;
    width: 33vw;
}

.App .MainRow a{
    width: 35vw;
    clip-path: inset(0);
    --f: 1.08;
}

.App .MainRowPhoto{
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
    position: relative;
}

.MainRow a:hover img.MainRowPhoto{
    transform: scale(var(--f));
    cursor: pointer;
}

.App .Main section{
    margin-bottom: 3vh;
}

.App .Main h2{
    text-align: center;
}




@media (max-width: 800px) {
    .navigation{
        display: none!important;
    }
    h1{
        font-size: 10vw;
        top: 65%
    }
    .HeaderImage{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    .Header{
        height: 30vh;
    }
    h3{
        font-size: 1.5vh;
    }
    #header1{
        margin-left: 0.5vh;
    }
    #header2{
        margin-left: 0.5vh;
        margin-right: 0.5vh;
    }
    .HeaderMenu{
        height: 17vh;
        bottom: -9vh;
    }
    .HeaderMenuItem a{
        padding: 0;
    }
    .HeaderMenuItem a img{
        width: 25vw;
        height: 12vh;
        margin-top: 0.5vh;
        margin-right: 0.2vh;
    }
    .HeaderMenuItem svg{
    height: 5.5vh;
    }

    .App .Main{
        padding-top:10px;
    }
    .App .MainRow{
        flex-direction: column;
        width: 95vw;
    }
    .App .MainRow a {
        width: 95vw;
    }
    .App .MainRowText{
        width: 95vw;
    }
    .App .MainRow img{
    width: 100%;
    }
    .App #rotate{
        flex-direction: column-reverse;
    }
}